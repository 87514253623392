import { SWRConfiguration } from 'swr';
import {
  ApiHook,
  buildUrlQuery,
  QueryParams,
  useCreateSWREndpoint,
} from './use-swr-endpoint';
import {
  formatAddress as formatAddressBase,
  FormattableAddress,
} from '@hooks/use-format-address';
import { OrganizationBankInfo } from '@models/organization-bank-info';
import {
  Organization,
  OrganizationPolicies,
  OrganizationSettings,
  OrgFinancialCountry,
} from '@models/organization';
import { OrganizationTreasurer } from '@models/organization-treasurer';
import {
  OrganizationOverrideCode,
  OrganizationRecommendedCode,
} from '@models/payment-category';
import {
  OrganizationChildrenPagination,
  Pagination,
  PaymentHistoriesPagination,
  DepositReportsPagination,
} from '@models/pagination';
import { PaymentCategoriesResponse } from '@models/http/organization-payment-categories-response';
import { PaymentCurrency } from '@models/payment-schedule';

// slightly less than 5 minutes to ensure we get the data before expiration
export const FIVE_MIN_REFRESH_INTERVAL = 290_000;

export interface OrganizationParams {
  id?: string;
  options?: SWRConfiguration;
}
export interface OrganizationsParams {
  name?: string;
  page?: number; // 1-indexed
}
export type SortDirection = 'asc' | 'desc';
export interface OrganizationChildrenParams {
  id?: string;
  'sort[name]'?: SortDirection;
  'sort[status]'?: SortDirection;
  options?: SWRConfiguration;
}

export interface OrganizationPaymentHistoryParams extends OrganizationParams {
  page?: number;
  pageSize?: number;
  startDate?: string;
  endDate?: string;
  onlyPendingTransfer?: boolean;
  search?: string;
}

export interface OrganizationDepositReportsParams extends OrganizationParams {
  page?: number;
  pageSize?: number;
  year?: string;
}

export const formatAddress = (
  organization: FormattableAddress,
  multipleLines = false
) => {
  return formatAddressBase(organization, multipleLines);
};

const USA_ALIASES = [
  'Guam',
  'Marshall Islands',
  'Micronesia, Federated States',
  'Northern Mariana Islands',
  'Palau',
];
const ALLOWED_COUNTRIES = ['USA', 'Canada', ...USA_ALIASES];

/**
 * More or less taken from the backend logic.
 * Refer to method `finance_country` in bank_account.rb
 * @see https://gitlab.nadadventist.org/adventist-giving/adventist-giving/-/blob/main/api/app/models/bank_account.rb?ref_type=heads#L55
 */
export const getOrganizationFinancialCountry = (
  organization: Organization
): OrgFinancialCountry => {
  if (organization.id && organization.id.slice(0, 2) !== 'AN') {
    return null;
  }

  if (organization.country) {
    if (!ALLOWED_COUNTRIES.includes(organization.country)) {
      return null;
    }

    if (USA_ALIASES.includes(organization.country)) {
      return 'USA';
    } else {
      return organization.country as OrgFinancialCountry;
    }
  }

  if (!organization.id) {
    return null;
  }

  if (organization.id.startsWith('AN6')) {
    return 'Canada';
  } else if (organization.id.startsWith('AN44')) {
    // Bermuda
    return null;
  }

  return 'USA';
};

export const getOrganizationCurrency = (
  organization: Organization
): PaymentCurrency | null => {
  const country = getOrganizationFinancialCountry(organization);
  if (country === 'USA') {
    return 'USD';
  } else if (country === 'Canada') {
    return 'CAD';
  } else {
    return null;
  }
};

export const useGetOrganization = ({
  id,
}: OrganizationParams): ApiHook<Organization> => {
  const url = `/api/v3/organizations/${id}`;
  const shouldFetch = !!id;
  const { data, error, mutate } = useCreateSWREndpoint<Organization>({
    url,
    shouldFetch,
  });
  const isLoading = !data && !error;
  return { data, error, isLoading, mutate };
};

export const useGetOrganizationBankInfo = ({
  id,
}: OrganizationParams): ApiHook<OrganizationBankInfo> => {
  const url = `/api/v3/organizations/${id}/bank_info`;
  const shouldFetch = !!id;
  return useCreateSWREndpoint<OrganizationBankInfo>({ url, shouldFetch });
};

export const useOrganizationDepositReports = ({
  id,
  page = 1,
  pageSize = 25,
  year = '',
}: OrganizationDepositReportsParams): ApiHook<DepositReportsPagination> => {
  if (page < 1) {
    page = 1;
  }
  const query = buildOrganizationDepositReportsQuery({
    page,
    pageSize,
    year,
  });
  const url = `/api/v3/organizations/${id}/deposit_reports${query}`;
  const shouldFetch = !!id;
  return useCreateSWREndpoint<DepositReportsPagination>({
    url,
    shouldFetch,
    options: {
      refreshInterval: FIVE_MIN_REFRESH_INTERVAL,
    },
  });
};

export const useOrganizationPaymentHistories = ({
  id,
  page = 1,
  pageSize = 20,
  startDate = '',
  endDate = '',
  onlyPendingTransfer = false,
  search = '',
}: OrganizationPaymentHistoryParams): ApiHook<PaymentHistoriesPagination> => {
  if (page < 1) {
    page = 1;
  }
  const queryParams = {
    'page[number]': page,
    'page[size]': pageSize,
    'filter[created_on_or_after]': startDate,
    'filter[created_on_or_before]': endDate,
    'filter[only_pending_transfer]': onlyPendingTransfer,
    'filter[payment_id]': '',
    'filter[user_search]': '',
  };

  // Filter by payment_id if search string is numeric, otherwise by user_search.
  if (/^-?\d+$/.test(search)) {
    queryParams['filter[payment_id]'] = search;
  } else {
    queryParams['filter[user_search]'] = search;
  }

  const query = buildUrlQuery(queryParams);
  const url = `/api/v3/organizations/${id}/payment_histories${query}`;
  const shouldFetch = !!id;
  return useCreateSWREndpoint<PaymentHistoriesPagination>({
    url,
    shouldFetch,
    options: {
      refreshInterval: FIVE_MIN_REFRESH_INTERVAL,
    },
  });
};

export const useGetOrganizationTreasurers = ({
  id,
}: OrganizationParams): ApiHook<OrganizationTreasurer> => {
  const url = `/api/v3/organizations/${id}/treasurers`;
  const shouldFetch = !!id;
  return useCreateSWREndpoint<OrganizationTreasurer>({ url, shouldFetch });
};

export const useOrganizations = ({
  name,
  page,
}: OrganizationsParams): ApiHook<Pagination<Organization>> => {
  const query = buildUrlQuery({ name, 'page[size]': 50 });
  const url = `/api/v3/organizations${query}`;
  return useCreateSWREndpoint<Pagination<Organization>>({ url });
};

export const useOrganizationPaymentCategories = ({
  id,
}: OrganizationParams): ApiHook<PaymentCategoriesResponse> => {
  const url = `/api/v3/organizations/${id}/payment_categories`;
  const shouldFetch = !!id;
  return useCreateSWREndpoint<PaymentCategoriesResponse>({ url, shouldFetch });
};

export const useGetNextRecommendedCode = ({
  id,
}: OrganizationParams): ApiHook<OrganizationRecommendedCode> => {
  const url = `/api/v3/organizations/${id}/payment_categories/recommended_code`;
  const shouldFetch = !!id;
  return useCreateSWREndpoint<OrganizationRecommendedCode>({
    url,
    shouldFetch,
  });
};

export const useGetOverrideCodes = ({
  id,
}: OrganizationParams): ApiHook<OrganizationOverrideCode[]> => {
  const url = `/api/v3/organizations/${id}/payment_category_codes`;
  const shouldFetch = !!id;
  return useCreateSWREndpoint<OrganizationOverrideCode[]>({
    url,
    shouldFetch,
  });
};

export const useGetOrganizationPolicies = ({
  id,
  options,
}: OrganizationParams): ApiHook<OrganizationPolicies> => {
  const url = `/api/v3/organizations/${id}/policies`;
  const shouldFetch = !!id;
  return useCreateSWREndpoint<OrganizationPolicies>({
    url,
    shouldFetch,
    options,
  });
};

export const useGetOrganizationChildren = ({
  id,
  options,
  ...sort
}: OrganizationChildrenParams): ApiHook<OrganizationChildrenPagination> => {
  const url = `/api/v3/organizations/${id}/children${buildUrlQuery(
    sort as QueryParams
  )}`;
  const shouldFetch = !!id;
  return useCreateSWREndpoint<OrganizationChildrenPagination>({
    url,
    shouldFetch,
    options: {
      refreshInterval: FIVE_MIN_REFRESH_INTERVAL,
    },
  });
};

export const useGetOrganizationSettings = ({
  id,
  options,
}: OrganizationParams): ApiHook<OrganizationSettings> => {
  const url = `/api/v3/organizations/${id}/settings`;
  const shouldFetch = !!id;
  return useCreateSWREndpoint<OrganizationSettings>({
    url,
    shouldFetch,
    options,
  });
};

const buildOrganizationDepositReportsQuery = ({
  page = 1,
  pageSize = 25,
  year = '',
}: OrganizationDepositReportsParams) => {
  const queryParams: QueryParams = {
    'page[number]': page,
    'page[size]': pageSize,
    'filter[year]': year,
  };
  return buildUrlQuery(queryParams);
};
