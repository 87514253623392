export interface FormattableAddress {
  address?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postal_code?: string | null;
}

export const formatAddress = (
  address: FormattableAddress,
  multipleLines = false
) => {
  if (multipleLines) {
    const lines = [address.address ? address.address : ''];
    if (address.address2) {
      lines.push(`${address.address2}`);
    }
    let line3 = '';
    if (address.city) {
      line3 += ` ${address.city},`;
    }
    if (address.state) {
      line3 += ` ${address.state}`;
    }
    if (address.postal_code) {
      line3 += ` ${address.postal_code}`;
    }
    lines.push(line3);
    return lines.map((line, index) => <div key={index}>{line}</div>);
  }

  let content = address.address ? address.address : '';
  if (address.address2) {
    content += ` ${address.address2}`;
  }
  if (content) {
    content += ',';
  }
  if (address.city) {
    content += ` ${address.city},`;
  }
  if (address.state) {
    content += ` ${address.state}`;
  }
  if (address.postal_code) {
    content += ` ${address.postal_code}`;
  }
  return content.trim();
};
